<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.contractId ? $t('common.form.title.editContract')   + ' (' + this.payload.contractId + ')' : $t('common.form.title.createContract')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        size="s"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group>
                            <template v-slot:label>
                                {{ $t('common.label.contract.upload_file') }}
                            </template>
                            <b-form-file @change="fileChanged" accept=".pdf"></b-form-file>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <zw-input-group v-model="form.file"
                                        name="file"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        :readonly="true"
                                        size="s"
                        >
                            <template #append-slot>
                                <b-input-group-append v-if="form.file">
                                    <b-button variant="info"
                                              @click="$root.openDocument(form.file, 'contracts')"
                                    >
                                        <font-awesome-icon icon="eye"/>
                                    </b-button>
                                </b-input-group-append>
                            </template>
                        </zw-input-group>
                    </b-col>
                </b-row>

                <template>
                    <b-row>
                        <b-col cols="3">
                            <zw-input-group v-model="form.prefix"
                                            name="prefix"
                                            :label-prefix="labelPrefix"
                                            validate="required"
                                            size="s"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-input-group v-model="form.year"
                                            name="year"
                                            :label-prefix="labelPrefix"
                                            :readonly="true"
                                            size="s"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-input-group v-model="form.counter"
                                            name="counter"
                                            :label-prefix="labelPrefix"
                                            :readonly="true"
                                            size="s"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-input-group v-model="form.document_number"
                                            name="document_number"
                                            :label-prefix="labelPrefix"
                                            :readonly="true"
                                            size="s"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="form.options">
                        <b-col cols="7" style="overflow-y: scroll;max-height: 500px;">
                            <b-table striped show-empty :items="form.options" :fields="tableFields">
                                <template #cell(name)="row">
                                    {{ row.item.name }}
                                    <template v-if="row.item.type == 'Button' || row.item.type == 'Choice'">
                                        <br>
                                        {{ $t('settings.attribute.label.options') }} : {{ row.item.options }}
                                    </template>
                                </template>
                                <template #cell(value)="row">
                                    <div>
                                        <template v-if="form.options[row.index]['fixed']">
                                            <template v-if="row.item.type == 'Button' || row.item.type == 'Choice'">
                                                <multiselect
                                                    v-model="form.options[row.index]['value']"
                                                    :options="row.item.options"
                                                    select-label=""
                                                />
                                            </template>
                                            <template v-else>
                                                <zw-textarea-group v-model="form.options[row.index]['value']"
                                                                   disable-label
                                                                   name="values"/>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <b-row>
                                                <b-col
                                                    :cols="form.options[row.index]['value'] ? (form.options[row.index]['value'].includes('custom_field.') ? 10 : 12) : 12">
                                                    <multiselect
                                                        v-model="form.options[row.index]['value']"
                                                        :options="columnOptions.map(option=>option.value)"
                                                        :custom-label="opt => getOption(opt)"
                                                        select-label=""
                                                        :multiple="true"
                                                    />
                                                    <template
                                                        v-if="row.item.options.length==2 && row.item.options.includes('Off') && row.item.options.includes('Yes')">
                                                        <zw-select-group
                                                            v-model="form.options[row.index]['checkbox_value']"
                                                            :options="form.custom_field_options[form.options[row.index]['value']]"
                                                            v-if="form.custom_field_options[form.options[row.index]['value']]"
                                                            disable-label
                                                            size="sm"
                                                        />
                                                    </template>
                                                    <template v-if="form.options[row.index]['value']=='customer.sign'">
                                                        <zw-select-group v-model="form.options[row.index]['signer_key']"
                                                                         size="sm"
                                                                         name="signer_key"
                                                                         :label-prefix="labelPrefix"
                                                                         :options="[
                                                                             {value:'default',text:'Default'},
                                                                             {value:'client',text:'Client'},
                                                                             {value:'company',text:'Company'}
                                                                             ]"
                                                        />
                                                    </template>
                                                </b-col>
                                                <b-col cols="1"
                                                       class="col-1 d-flex align-self-center"
                                                       v-if="form.options[row.index]['value'] && form.options[row.index]['value'].includes('custom_field.')">
                                                    <b-button
                                                        variant="info"
                                                        class="btn-sm"
                                                        @click="openCustomerCustom(form.options[row.index]['value'])">
                                                        <font-awesome-icon icon="arrow-right"/>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </template>
                                    </div>
                                </template>
                                <template #cell(field)="row">
                                    <template
                                        v-if="!form.options[row.index]['fixed'] && (row.item.type != 'Button'  || row.item.type == 'Choice')">
                                        {{ allFields[form.options[row.index]['value']] }}
                                    </template>
                                </template>
                                <template #cell(fixed)="row">
                                    <b-form-checkbox
                                        v-model="form.options[row.index]['fixed']"
                                        switch
                                        :value="1"
                                        :unchecked-value="0"
                                    >
                                    </b-form-checkbox>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="5">
                            <b-row>
                                <b-col sm="4">
                                    <search-field
                                        v-if="form.id"
                                        v-model="form.customer"
                                        name="customer-contract.customer"
                                        :url="customersSearchUrl"
                                        :callback="customerSelected"
                                        @input-object="customerSelected"
                                    ></search-field>
                                </b-col>
                                <b-col sm="2">
                                    <zw-switch-group v-if="form.id"
                                                     v-model="form.placeholders"
                                                     name="placeholders"
                                                     @input="customerSelected"
                                    />
                                </b-col>
                            </b-row>
                            <b-row v-if="form.id">
                                <b-col class="text-right">
                                    <b-button
                                        @click="reloadPreview()"
                                        class="mb-2"
                                    >
                                        <font-awesome-icon icon="sync"/>
                                    </b-button>
                                </b-col>
                            </b-row>

                            <iframe width="100%"
                                    height="100%"
                                    :style="[source ? {'min-height':'700px', 'border':'none'} : {'border': 'none'}]"
                                    :src="source"
                            ></iframe>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="7">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                        <b-col sm="5"></b-col>
                    </b-row>
                </template>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'contract-modal',
    components: {ZwModelSelectorGroup},
    mixins: [commonSave],
    data() {
        return {
            customersSearchUrl: window.apiUrl + '/customer-search',
            loading: true,
            source: null,
            previewFinishLoad: true,
            allFieldsFinishLoad: true,
            payload: {},
            allFields: {},
            callback: null,
            form: {},
            labelPrefix: 'common.label.contract.',
            response: null,
            tableFields: [
                {
                    label: this.$t('common.label.contract.name'),
                    key: 'name',
                    tdClass: 'text-wrap',
                },
                {
                    label: this.$t('common.label.contract.value'),
                    key: 'value',
                    tdClass: 'text-wrap',
                },
                {
                    label: this.$t('common.label.contract.customer_value'),
                    key: 'field',
                    tdClass: 'text-wrap',
                },
                {
                    label: this.$t('common.label.contract.fixed'),
                    key: 'fixed',
                },
            ],
        }
    },
    mounted() {
    },
    computed: {
        columnOptions() {
            let options = []
            let self = this

            Object.keys(this.form.columns).forEach(key => {
                let label = this.form.columns[key]

                if (key.startsWith('custom_field.')) {
                    label = 'Custom: ' + label
                } else if (key.startsWith('_attribute.')) {
                    label = 'Attribute: ' + label
                } else if (key.startsWith('_price_list_')) {
                    label = 'Price list: ' + label
                } else if (key.startsWith('cp.')) {
                    label = 'Contact person: ' + self.$t('customer.label.' + label)
                } else {
                    label = self.$t('customer.label.' + label)
                }

                options.push({
                    text: label,
                    value: key,
                })
            })

            options.sort(function (a, b) {
                return a.text.localeCompare(b.text)
            });

            return options;
        },
    },
    methods: {
        ...mapGetters('ContractSettings', ['getContract']),
        ...mapGetters('CustomerContract', ['getContractPreview', 'getContractAllFields']),
        reloadPreview() {
            let sendData = this.form
            sendData['preview'] = true
            sendData['contract'] = this.form.id
            sendData['settingsPreview'] = true
            sendData = JSON.parse(JSON.stringify(sendData))
            if (this.previewFinishLoad) {
                this.load = true
                this.previewFinishLoad = false
                this.$store.dispatch('CustomerContract/fetchContractPreview', sendData)
                    .then(() => {
                        this.source = 'data:application/pdf;base64,' + this.getContractPreview().content
                    }).finally(() => {
                    this.previewFinishLoad = true
                    this.load = false
                })
            }
        },
        customerSelected() {
            if (this.form.customer && this.form.id) {
                this.getAllFields()
                this.reloadPreview()
            } else if (this.form.id && this.form.placeholders) {
                this.reloadPreview()
            }
        },
        getAllFields() {
            let sendData = JSON.parse(JSON.stringify(this.form))
            if (this.allFieldsFinishLoad) {
                this.allFieldsFinishLoad = false
                this.$store.dispatch('CustomerContract/fetchAllContractFields', sendData)
                    .then(() => {
                        this.allFields = this.getContractAllFields();
                    }).finally(() => {
                    this.allFieldsFinishLoad = true
                    this.load = false
                })
            }
        },
        getOption(opt) {
            let option = this.columnOptions.find(option => option.value == opt)
            if (option) {
                return option.text
            }
        },
        openCustomerCustom(name) {
            const routeData = this.$router.resolve({
                path: '/custom-fields',
                query: {name: name.replace(/custom_field./i, ''), model: 'customer'}
            });
            window.open(routeData.href, '_blank');
        },
        shown() {
            this.loading = false
            if (this.payload.contractId) {
                this.$store.dispatch('ContractSettings/fetchContract', this.payload.contractId)
                    .then(() => {
                        this.form = this.getContract()
                        this.form.year = new Date().getFullYear()
                        this.form.placeholders = 1
                        this.$refs['observer'].reset()
                    })
                    .finally(() => {
                        this.loading = false;
                        this.reloadPreview()
                    })
            }
        },
        fileChanged(event) {
            this.loading = true
            this.form.uploadedFile = event.target.files[0]
            let formData = new FormData();
            formData.append('file', this.form.uploadedFile);
            formData.append('id', this.form.id);

            axios.post(window.apiUrl + '/upload-contract', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                'skip_loading': true,
            })
                .then(result => {
                    this.response = result.data
                    this.form.options = this.response.fields;
                    this.form.columns = this.response.columns;
                    this.form.file = this.response.fileName;
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('ContractSettings/saveContract', formData)
                        .then((response) => {
                            this.commonAfterSave(response, this.sidebar)
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false

                                this.$refs['observer'].setErrors(errors)
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>

<style>
.max-value {
    max-width: 200px;
    position: relative;
}
</style>